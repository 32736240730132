import { Route, Routes } from "react-router-dom"
import React from "react";
import Main from "./Flag";


export default function App() {
  return (
    <Routes>
      <Route path='*' element={<Main />}/>
    </Routes>
  );
}
