import './App.css';
import React from 'react';

function Main() {

  return (
    <div className="App">
      <div className='flag'>
        <h1>We moved!</h1>
        <p>Get your Adobe subscription on our new website:</p>
        <code><a href='https://cheapcc.net'>cheapcc.net</a></code>
      </div>
    </div>
  );
}

export default Main;
